import React, { useState } from "react"
import { Wrapper, NavTop, NavMiddle, NavBottom, NavOpen } from "./style"
import Logo from "./logo.js"
import { BiMenu } from "react-icons/bi"

export const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <>
      <Wrapper>
        <NavTop>
          <ul>
            <li>Zadzwoń do nas pod nr: +48 513 076 423</li>
            <li>Bezpłatny okres próbny</li>
            <li>Otwórz własne Centrum Nauki GENIUS</li>
          </ul>
        </NavTop>

        <NavMiddle>
          <Logo />
          <div>
            <p>Genius Wilanów</p>
            <p>ul. Adama Branickiego 9 lok 118</p>
            <p>02-797 Warszawa</p>
          </div>

          <a href="/#zapis">Zapisz się</a>
        </NavMiddle>

        <NavBottom menuOpen={menuOpen}>
          <NavOpen onClick={() => setMenuOpen(!menuOpen)}>
            <BiMenu />
          </NavOpen>
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/#o-programie">O programie</a>
            </li>
            <li>
              <a href="/#kurs">Zalety</a>
            </li>
            <li>
              <a href="/#kontakt">Kontakt</a>
            </li>
          </ul>
        </NavBottom>
      </Wrapper>
    </>
  )
}
