import styled, { css } from "styled-components"

export const Wrapper = styled.header``

export const NavOpen = styled.button`
  display: none;
  position: absolute;
  left: 30px;
  top: 70px;
  background: none;
  font-size: 20px;
  border: 0;
  outline: 0;
  color: ${props => props.theme.colors.secondary};

  ${props => props.theme.mq.tablet} {
    display: block;
  }
`

export const NavTop = styled.div`
  color: #5f5f5f;
  font-size: 12px;
  padding: 10px;
  background: #ececec;

  ul {
    display: flex;
    padding: 0;
    max-width: ${props => props.theme.container.maxWidth};
    margin: 0 auto;

    li {
      display: block;
      display: block;
      flex: 1;
      margin: 0;
      text-align: center;
    }
  }

  ${props => props.theme.mq.tablet} {
    & {
      display: none;
    }
  }
`

export const NavMiddle = styled.div`
  display: flex;
  max-width: ${props => props.theme.container.maxWidth};
  margin: 0 auto;
  padding: 20px;
  justify-content: space-between;
  align-items: center;

  .gatsby-image-wrapper {
    width: 150px;
  }

  div {
    p {
      margin: 0;
      text-align: center;
      font-size: 14px;
      color: #3e3e3e;
    }
  }

  a {
    display: block;
    background: #1475bc;
    text-align: center;
    padding: 14px 25px;
    font-size: 14px;
    height: auto;
    border-radius: 50px;
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
  }

  ${props => props.theme.mq.tablet} {
    flex-direction: column;
    div {
      display: none;
    }
    a {
      margin-top: 20px;
    }
  }
`

export const NavBottom = styled.nav`
  background: ${props => props.theme.colors.primary};

  ul {
    display: flex;
    padding: 0;
    max-width: ${props => props.theme.container.maxWidth};
    margin: 0 auto;

    li {
      display: block;
      flex: 1;
      margin: 0;

      a {
        display: block;
        text-align: center;
        text-decoration: none;
        color: #272727;
        padding: 10px;
        border-left: 1px solid #b5911b;

        &:hover {
          background: #ffd444;
          color: #5c512d;
        }
      }

      &:last-child {
        border-right: 1px solid #b5911b;
      }
    }
  }

  ${props => props.theme.mq.tablet} {
    ul {
      flex-direction: column;
      display: none;
    }
    div {
      display: none;
    }
  }

  ${props =>
    props.menuOpen &&
    css`
      ul {
        display: block;
      }
    `}
`
