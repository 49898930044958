import React from "react"
import { ThemeProvider } from "styled-components"

import { Header } from "./Header"
import { Footer } from "./Footer"

import { theme } from "./theme"
import { GlobalStyles } from "./style"

export const Layout = ({ children }) => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Header />
        <main>{children}</main>
        <Footer />
      </ThemeProvider>
    </>
  )
}
