import { createGlobalStyle } from "styled-components"
import { reset } from "styled-reset"

export const GlobalStyles = createGlobalStyle`
  ${reset}

  html {
    box-sizing: border-box;
    font-size: 62.5%;
  }
  
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    font-size: 1.6rem;
    font-family: ${props => props.theme.font.name.main};
    font-weight: 300;
  }
  
  h1, h2, h3, h4, h5 {
    margin: 0;
    font-size: ${props => props.theme.font.size.header}
  }
  
  button {
    padding: 0;
    cursor: pointer;
  }
  
  ul {
    padding: 0;
    margin: 0;
  }
`
