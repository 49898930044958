export const colors = {
  primary: `#f3c62c`,
  secondary: `#006baf`,

  silver: `#f3f3f3`,

  white: `hsl(0, 0%, 100%)`,
  black: `hsl(0, 0%, 0%)`,
  grey100: `hsl(0, 0%, 35%)`,
  grey200: `hsl(0, 0%, 74%)`,
  grey300: `hsl(0, 0%, 87%)`,
  grey400: `hsl(0, 0%, 91%)`,
  grey500: `hsl(0, 0%, 97%)`,
}

export const font = {
  size: {
    header: `2.5rem`,
    paragraph: `1.7rem`,
    button: `1.5rem`,
  },
  name: {
    main: `'Raleway', sans-serif`,
    primary: `'Montserrat', sans-serif`,
    secondary: ``,
  },
}

export const container = {
  maxWidth: `960px`,
}

export const mq = {
  desktop: "@media (max-width: 1150px)",
  tablet: "@media (max-width: 767px)",
  phone: "@media (max-width: 320px)",
}

export const theme = {
  colors,
  font,
  container,
  mq,
}
