import React from "react"
import styled from "styled-components"

const Wrapper = styled.footer`
  background: #303030;
  padding: 50px;
  text-align: center;
  color: #696969;
`

export const Footer = () => {
  return (
    <>
      <Wrapper>
        <p>Genius {new Date().getFullYear()}</p>
      </Wrapper>
    </>
  )
}
